import alloyAnalytics from '@telus/alloy-analytics'
import { CarouselProps, itemsProps } from '../../blocks/core/Carousel/Carousel'

type ContextAnalyticsProps = {
  index: number
  item: itemsProps
  itemsLength: number
}

type SlideChangeProps = Pick<CarouselProps, 'sysID' | 'entryTitle' | 'itemLabel' | 'carouselNavigationStyle'> &
  Pick<ContextAnalyticsProps, 'index' | 'item' | 'itemsLength'>

const TOOL_TYPE = 'carousel'

const alloyEvents = {
  slideChange: ({
    sysID,
    entryTitle,
    itemLabel,
    carouselNavigationStyle,
    index,
    item,
    itemsLength,
  }: SlideChangeProps) => {
    const toolSubmit = {
      name: `${entryTitle}-slide_change`,
      toolName: `carousel-${entryTitle}`,
      toolType: TOOL_TYPE,
      toolUsageID: sysID,
      toolSelections: getToolSelections({ index, item, itemsLength, itemLabel, carouselNavigationStyle }),
    }
    alloyAnalytics.event('toolSubmit').webInteraction(toolSubmit).fire()
  },
}

type GetToolSelectionsProps = Pick<ContextAnalyticsProps, 'index' | 'item' | 'itemsLength'> &
  Pick<CarouselProps, 'itemLabel' | 'carouselNavigationStyle'>

function getToolSelections({ index, item, itemsLength, itemLabel, carouselNavigationStyle }: GetToolSelectionsProps) {
  const analyticsIndex = index + 1
  const { tabTitle } = item

  const navigationStyles: Record<CarouselProps['carouselNavigationStyle'], string> = {
    tabs: `carousel_tab_${analyticsIndex}:${tabTitle}`,
    basic: `${itemLabel}_${analyticsIndex}_of_${itemsLength}`,
    thumbnail: `carousel_thumbnail_${analyticsIndex}_of_${itemsLength}`,
  }

  return navigationStyles[carouselNavigationStyle]
}

export default alloyEvents
