import { ICarouselItem, ICarouselUdsFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { BlockRenderer } from 'src/siteBuilder/renderer/contentful/BlockRenderer'

import Carousel, { CarouselProps } from './Carousel'
import { ContentfulAnalyticsEventType } from '@/siteBuilder/utils/types/analytics'

const ContentfulCarousel = withContentful<ICarouselUdsFields, CarouselProps>(Carousel, {
  carouselNavigationStyle: (props) =>
    props.fields?.carouselNavigationStyle?.toLowerCase() as Lowercase<ICarouselUdsFields['carouselNavigationStyle']>,
  navigationIconPosition: (props) =>
    props.fields?.navigationIconPosition.toLowerCase() as Lowercase<ICarouselUdsFields['navigationIconPosition']>,
  navigationIconSize: (props) =>
    props.fields?.navigationIconSize.toLowerCase() as Lowercase<ICarouselUdsFields['navigationIconSize']>,
  sysID: (props) => props.sys.id,
  entryTitle: (props) => props.fields.entryTitle,
  carouselItems: (props) => {
    return props.fields.carouselItems
      .filter((item): item is ICarouselItem => item && item.sys.contentType?.sys.id === 'carouselItem')
      .map((item) => ({
        tabTitle: item.fields.tab,
        thumbnail: item.fields.thumbnail?.fields?.file?.url,
        children: <BlockRenderer block={item.fields.carouselItemContent} />,
        fullBleedBackground: item.fields.fullBleedBackground,
        inverseNavigationText: item.fields.inverseNavigationText,
        analyticsEvent: item.fields.analyticsEvent as unknown as ContentfulAnalyticsEventType,
      }))
  },
  enablePeeking: (props) => props?.fields?.enablePeeking,
})

export default ContentfulCarousel
