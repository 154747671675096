import React, { ReactNode, useEffect, useState, useCallback, useContext } from 'react'
import { Carousel as UDSCarousel, FlexGrid, Box } from '@telus-uds/components-web'
import useInteractionAnalyticsEvents from 'src/hooks/useInteractionAnalyticsEvents'
import { ContentfulAnalyticsEventType } from 'src/siteBuilder/utils/types/analytics'
import alloyEvents from '../../../utils/alloyAnalytics/carousel'
import { contentfulLoader } from '../Image/contentfulLoader'
import { SiteBuilderContext } from '@/siteBuilder/renderer/context/SiteBuilderContext'

export type itemsProps = {
  tabTitle?: string
  thumbnail?: string
  children: ReactNode
  fullBleedBackground?: string
  inverseNavigationText?: boolean
  analyticsEvent?: ContentfulAnalyticsEventType
}

type navigationIconPosition = 'inside' | 'outside' | 'edge'
type navigationIconSize = 'default' | 'small' | 'large'
type navigationStyles = 'basic' | 'tabs' | 'thumbnail'

export type CarouselProps = {
  sysID: string
  entryTitle: string
  itemLabel: string
  carouselNavigationStyle?: navigationStyles
  carouselItems: itemsProps[]
  navigationIconPosition: navigationIconPosition
  navigationIconSize: navigationIconSize
  autoPlay: boolean
  enablePeeking: boolean
  hasFullBleedBackground: boolean
}

const Carousel = ({
  sysID,
  entryTitle,
  itemLabel = '',
  carouselNavigationStyle = 'tabs',
  carouselItems = [],
  navigationIconPosition = 'edge',
  navigationIconSize = 'default',
  autoPlay = false,
  enablePeeking = false,
  hasFullBleedBackground = false,
}: CarouselProps) => {
  const [isAnalyticsTriggered, setIsAnalyticsTriggered] = useState(false)
  const [analytics, setAnalytics] = useState<ContentfulAnalyticsEventType>(null)
  const { isWebpSupported } = useContext(SiteBuilderContext)

  useInteractionAnalyticsEvents(analytics, isAnalyticsTriggered)

  const [currentBackground, setCurrentBackground] = React.useState('lightest')
  const {
    locale: { language },
  } = useContext(SiteBuilderContext)

  useEffect(() => {
    setIsAnalyticsTriggered(true), [analytics]

    // Setting changedIndex as null to prevent the first carousel item's analytics from firing on page-load
    // Setting activeIndex to 0 so fullBleedBackground will display properly for the first tab style carousel item on page-load
    onIndexChangedCallback(null, 0)
  }, [])

  const onIndexChangedCallback = useCallback((changedIndex, activeIndex) => {
    carouselItems.map((item, index, arrayRef) => {
      if (index === activeIndex) {
        // Prevents analytics from firing for the first carousel item on page-load
        if (changedIndex !== null) {
          setAnalytics(item.analyticsEvent)

          alloyEvents.slideChange({
            sysID,
            entryTitle,
            itemLabel,
            carouselNavigationStyle,
            index: activeIndex,
            item,
            itemsLength: arrayRef.length,
          })
        }
        if (hasFullBleedBackground || carouselNavigationStyle === 'tabs') {
          setCurrentBackground(item.fullBleedBackground)
        }
      }
    })
  }, [])

  const renderTabs =
    carouselNavigationStyle === 'tabs'
      ? carouselItems.map((item) => {
          return {
            title: item.tabTitle,
            inverse: item.inverseNavigationText,
          }
        })
      : undefined

  const renderThumbnails =
    carouselNavigationStyle === 'thumbnail'
      ? carouselItems.map((item) => {
          // Width to 200px, Twice the largest width of the thumbnail
          return {
            src: contentfulLoader(isWebpSupported)({
              src: item.thumbnail,
              width: 200,
            }),
          }
        })
      : undefined

  let testID = ''
  if (carouselNavigationStyle === 'thumbnail') {
    testID = 'carousel-item-thumbnail-testid'
  } else if (carouselNavigationStyle === 'tabs') {
    testID = 'carousel-item-tab-testid'
  } else {
    testID = 'carousel-item-testid'
  }

  const renderCarouselItems =
    carouselItems.map((item, i) => (
      <UDSCarousel.Item key={item.tabTitle + i}>
        <div data-testid={testID} />
        {item.children}
      </UDSCarousel.Item>
    )) ?? []

  return (
    <FlexGrid limitWidth={false} gutter={false}>
      <FlexGrid.Row>
        <FlexGrid.Col>
          <Box variant={{ background: currentBackground }}>
            <FlexGrid gutter={!enablePeeking}>
              <FlexGrid.Row>
                <FlexGrid.Col>
                  <UDSCarousel
                    enablePeeking={enablePeeking}
                    autoPlay={autoPlay}
                    slideDuration={10}
                    transitionDuration={2}
                    itemLabel={itemLabel}
                    thumbnails={renderThumbnails}
                    tabs={renderTabs}
                    previousNextNavigationPosition={navigationIconPosition}
                    previousNextIconSize={navigationIconSize}
                    onIndexChanged={(changedIndex, activeIndex) => onIndexChangedCallback(changedIndex, activeIndex)}
                    copy={language}
                  >
                    {renderCarouselItems}
                  </UDSCarousel>
                </FlexGrid.Col>
              </FlexGrid.Row>
            </FlexGrid>
          </Box>
        </FlexGrid.Col>
      </FlexGrid.Row>
    </FlexGrid>
  )
}

export default Carousel
