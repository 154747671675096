// A utility to set the analytics event object inside window.dataLayer and window.adobe.dataLayer, only if window exists
import { useEffect } from 'react'
import { ContentfulAnalyticsEventType } from 'src/siteBuilder/utils/types/analytics'

// TODO: Alloy fields doesn't exists anymore

const useInteractionAnalyticsEvent = (analyticsEvent: ContentfulAnalyticsEventType, isAnalyticsTriggered = false) => {
  useEffect(() => {
    if (
      analyticsEvent &&
      isAnalyticsTriggered &&
      typeof window === 'object' &&
      'dataLayer' in window // dataLayer will eventually be removed
    ) {
      // dataLayer will eventually be deprecated
      const dataLayerEvent = {
        name: analyticsEvent?.fields?.eventName,
        action: analyticsEvent?.fields?.eventAction,
        details: analyticsEvent?.fields?.eventDetails,
      }

      window.dataLayer.event = dataLayerEvent
    }
  })
}

export default useInteractionAnalyticsEvent
